/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react';

type UseBusyReturn = {
  isBusy: (action: string) => boolean;
  setBusy: (action: string, state: boolean) => void;
  withBusy: (action: string, promise: Promise<any>) => Promise<any>;
};

const useBusy = (): UseBusyReturn => {
  const [busyStates, setBusyStates] = useState<Record<string, boolean>>({});

  const setBusy = useCallback((action: string, state: boolean) => {
    setBusyStates((prev) => ({ ...prev, [action]: state }));
  }, []);

  const isBusy = useCallback((action: string) => !!busyStates[action], [busyStates]);

  const withBusy = useCallback(
    async (action: string, promise: Promise<any>) => {
      setBusy(action, true);
      try {
        return await promise;
      } finally {
        setBusy(action, false);
      }
    },
    [setBusy],
  );

  return { isBusy, setBusy, withBusy };
};

export default useBusy;
